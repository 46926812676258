const config = {
  env: 'local',

  apiUrl: "https://api.skillmastergames.in/admin/",
  // apiUrl: "http://13.235.246.243:5566/admin/",
  // apiUrl: "https://api.amourtrendss.com/admin/",
  //  shipRocketApiUrl: "https://apiv2.shiprocket.in/v1/external/"

}

export default config;
